.profile__edit-button {
  width: 24px;
  height: 24px;
  background-color: #000000;
  background-image: url('../../../images/profile-edit-button.svg');
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  align-self: flex-start;
  margin-top: 33px;
  color: #FFFFFF;
}

.profile__edit-button:hover {
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  opacity: 0.6;
  cursor: pointer;
}

@media screen and (max-width: 715px) {
  .profile__edit-button {
    width: 18px;
    height: 18px;
    margin-top: 20px;
    margin-left: 10px;
  }
}
