.infotooltip__text {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0 36px 60px;
  text-align: center;
}
