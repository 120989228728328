.profile__add-button {
  width: 150px;
  height: 50px;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 2px;
  background-image: url('../../../images/profile-add-button.svg');
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000000;
}

.profile__add-button:hover {
  border: 2px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0.6;
}

@media screen and (max-width: 715px) {
  .profile__add-button {
    margin-top: 36px;
  }
}

@media screen and (max-width: 320px) {
  .profile__add-button {
    width: 100%;
    background-size: 16px;
  }
}
