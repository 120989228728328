.footer__copyright {
  margin-block-start: 0;
  margin-block-end: 0;
  max-width: 100%;
  font-family: 'Inter', ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #545454;
}

@media screen and (max-width: 320px) {
  .footer__copyright {
    font-size: 14px;
    line-height: 17px;
  }
}
