.header__logo {
  width: 142.01px;
  height: 33.4px;
  margin-bottom: 40.6px;
}

@media screen and (max-width: 950px) {
  .header__logo {
    margin-left: 27px;
  }
}

@media screen and (max-width: 715px) {
  .header__logo {
    width: 103.74px;
    height: 24.4px;
    margin-bottom: 31.6px;
  }
}
