.popup__text {
  padding: 0;
  width: calc(100% - 72px);
  height: 27px;
  font-family: 'Inter', ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  size: 27px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 15px auto 0 36px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-align: start;
}

.popup__text:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 560px) {
  .popup__text {
    width: calc(100% - 40px);
    margin: 0 20px 20px;
    font-size: 12px;
    line-height: 15px;
    height: 21.3px;
  }
}
