.register__sign-up {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border: none;
  border-radius: 2px;
  width: 358px;
  height: 50px;
  margin: 216px auto 0;
  padding: 0;
}
