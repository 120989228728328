.element__point {
  margin-block-start: 0;
  margin-block-end: 0;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  font-family: 'Inter', ‘Arial’, sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 225px;
}
