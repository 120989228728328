.footer {
  width: 882px;
  margin: 66px auto 0;
  padding-bottom: 60px;
}

@media screen and (max-width: 950px) {
  .footer {
    width: calc(100% - 38px);
  }
}

@media screen and (max-width: 560px) {
  .footer {
    margin: 48px auto 36px;
  }
}
