.popup__image-name {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  font-family: 'Inter', ‘Arial’, sans-serif;
  position: absolute;
  bottom: -25px;
  margin-block-start: 0;
  margin-block-end: 0;
}
