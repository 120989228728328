.popup__title {
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 34px 64px 32px 36px;
  font-family: 'Inter', ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
}

@media screen and (max-width: 560px) {
  .popup__title {
    font-size: 18px;
    line-height: 22px;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: auto;
    margin-bottom: 36px;
  }
}
