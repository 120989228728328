.header__email {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  text-decoration: none;
  margin-right: 24px;
  margin-block-start: 0;
  margin-block-end: 0;
}


