@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/Inter-Black.woff2") format("woff2"),
       url("../fonts/Inter-Black.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter-Regular.woff2") format("woff2"),
       url("../fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Inter-Bold.woff2") format("woff2"),
       url("../fonts/Inter-Bold.woff") format("woff");
}
