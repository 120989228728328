.profile__avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
  z-index: 3;
}

.profile__avatar:hover {
  opacity: 0.2;
  z-index: 1;
}

