.header {
  width: 882px;
  height: 74px;
  margin: 0 auto;
  padding-top: 45px;
  border-bottom: 1px solid rgba(84, 84, 84, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 950px) {
  .header {
    width: 100%;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, auto);
    height: 56px;
    padding-top: 28px;
  }
}
