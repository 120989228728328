.popup__save {
  width: 358px;
  height: 50.15px;
  background: #000000;
  margin: 23px 36px 36.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 2px;
  color:#ffffff;
  font-family: 'Inter', ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  padding: 0;
}

.popup__save:hover {
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

@media screen and (max-width: 560px) {
  .popup__save {
    width: calc(100% - 40px);
    height: 40px;
    margin: 31px 20px 30px;
    font-size: 14px;
    line-height: 17px;
  }
}
