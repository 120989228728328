.profile__name {
  max-width: 309px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 16px;
  font-family: 'Inter', ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 56px;
  color: #FFFFFF;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 715px) {
  .profile__name {
    max-width: 201px;
    font-size: 27px;
    text-align: center;
    margin-bottom: 14px;
  }
}
