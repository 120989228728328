.element__delete {
  height: 19.3px;
  width: 18px;
  background-image: url('../../../images/element-delete.svg');
  background-size: contain;
  background-color: rgba(255, 255, 255, 0);
  position: absolute;
  top: 18px;
  right: 15px;
  border: none;
  padding: 0;
}

.element__delete:hover {
  cursor: pointer;
  opacity: 0.7;
}
