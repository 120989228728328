.profile__text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 0 15px;
  grid-column-start: 2;
  grid-column-end: 3;
}

@media screen and (max-width: 715px) {
  .profile__text {
    width: 201px;
    margin-left: 0;
    justify-self: center;
    align-items: center;
  }
}
