.element__like {
  width: 23px;
  height: 20px;
  background-image: url('../../../images/element-like.svg');
  background-size: cover;
  background-repeat: no-repeat;
  /* margin-top: -4px; */
  margin-bottom: 3px;
  border: none;
  background-color: #ffffff;
  padding: 0;
}

.element__like:hover {
  opacity: 0.7;
  cursor: pointer;
}
