.popup__error {
  height: 15px;
  font-family: 'Inter', ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #FF0000;
  width: calc(100% - 72px);
  margin: 5px 36px 0;
}

@media screen and (max-width: 560px) {
  .popup__error {
    font-size: 8px;
    line-height: 10px;
    margin-top: 2px;
  }
}
