.login__data {
  font-family: "Inter", ‘Arial’, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-block-start: 0;
  background-color: #000000;
  width: 358px;
  height: 30px;
  margin-block-end: 0;
  padding: 0;
  margin: 30px auto 0;
  border: none;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
}
