.profile {
  width: 882px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 51px;
}

@media screen and (max-width: 950px) {
  .profile {
    width: calc(100% - 38px);
  }
}

@media screen and (max-width: 715px) {
  .profile {
    flex-direction: column;
    padding: 38.25px 0 36px;
  }
}
